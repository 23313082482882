<template>
  <div class="text-center">
    <h2 class="mt-10 text-4xl uppercase text-brand-blue font-amatic-sc">
      Leider haben Sie keinen Workshop gebucht.
    </h2>
    <div class="mt-10">
      <Button />
    </div>
  </div>
</template>

<script>
import Button from "../components/button.vue";

export default {
  components: { Button },
};
</script>
