<template>
  <router-link
    to="/"
    class="
      px-5
      py-3
      text-white
      bg-brand-blue
      rounded-lg
      font-amatic-sc
      text-3xl
      md:text-5xl
    "
  >
    Zurück
  </router-link>
</template>
